.navbar-nav{
    flex-wrap: wrap;
}
.card-text{
    background-color:rgba(255, 0, 0, 0.514) ;
    text-align: center;
    padding: 5px 10px;
}
.css-1yhpg23-MuiTableCell-root{
    color: white;
}